<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="agGrid-page container">
      <base-header />
      <base-ag-grid
        :columnDefs="columnDefs"
        :cellValueChanged="cellValueChanged"
        :datasource="datasource"
        :statusBar="statusBar"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import LeadCenterCustomGroupRenderer from '@/agGridV2/renderers/lead-center-custom-group.renderer'
import LeadCenterManualQualifiedRenderer from '@/agGridV2/renderers/manual-qualified.renderer'
import LeadCenterNavigation from '@/components/LeadCenterNavigation.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import StatusBar from '@/agGridV2/renderers/status-bar.renderer.vue'

export default {
  components: {
    LeadCenterNavigation,
    BaseHeader,
    BaseAgGrid,
    // eslint-disable-next-line vue/no-unused-components
    StatusBar,
    // eslint-disable-next-line vue/no-unused-components
    LeadCenterCustomGroupRenderer,
    // eslint-disable-next-line vue/no-unused-components
    LeadCenterManualQualifiedRenderer
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      statusBar: null,
      cellValueChanged: null,
    }
  },
  async mounted() {
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: 'StatusBar'
        }
      ]
    }
    const createSquare = (color, text) => {
      const square = document.createElement('div')
      square.style.width = '20px'
      square.style.height = '20px'
      square.style.backgroundColor = color
      square.style.display = 'flex'
      square.style.justifyContent = 'center'
      square.style.alignItems = 'center'
      square.style.color = 'white'
      square.style.fontSize = '10px'
      square.innerText = text
      return square
    }
    const createContainerWithSquares = (squaresInfo) => {
      const container = document.createElement('div')
      container.style.display = 'flex'
      container.style.gap = '5px'
      squaresInfo.forEach((square) => {
        const color = square.value ? 'green' : 'red'
        const text = square.label
        container.appendChild(createSquare(color, text))
      })
      return container
    }
    this.columnDefs = [
      {
        field: 'abbr_l',
        headerName: 'Abbreviation'
      },
      {
        field: 'setup_date',
        headerName: 'Setup Date',
        valueFormatter: (params) => {
          return SimpleHelper.dateFormatter(params.value)
        },
        filter: 'agDateColumnFilter'
      },
      {
        field: 'active_platform',
        headerName: 'Active Platform'
      },
      {
        field: 'url',
        headerName: 'URL'
      },
      {
        field: 'status',
        headerName: 'Status'
      },
      {
        field: 'total_contacts',
        headerName: 'Total Contacts',
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'last_7_days_contacts',
        headerName: 'Last 7 Days',
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'info',
        headerName: 'Info',
        tooltipField: 'info'
      },
      {
        field: 'services',
        headerName: 'Services',
        tooltipField: 'services'
      },
      {
        field: 'locations',
        headerName: 'Locations',
        tooltipField: 'locations'
      },
      {
        field: 'avc',
        headerName: 'AVC',
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'crcc',
        headerName: 'CRCC',
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'crlc',
        headerName: 'CRLC',
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'lead_app_info',
        headerName: 'Lead App Info',
        cellRenderer: (params) => {
          const squaresInfo = [
            { value: params.data.lead_app_info.cr, label: 'CR' },
            { value: params.data.lead_app_info.fo, label: 'FO' },
            { value: params.data.lead_app_info.jc, label: 'JC' },
            { value: params.data.lead_app_info.hs, label: 'HS' },
            { value: params.data.lead_app_info.sa, label: 'SA' },
            { value: params.data.lead_app_info.bz, label: 'BZ' },
            { value: params.data.lead_app_info.cn, label: 'CN' },
            { value: params.data.lead_app_info.jv, label: 'JV' },
            { value: params.data.lead_app_info.ng, label: 'NG' }
          ]
          return createContainerWithSquares(squaresInfo)
        },
        width: '250px'
      },
      {
        field: 'analytics_info',
        headerName: 'Analytics Info',
        cellRenderer: (params) => {
          const squaresInfo = [
            { value: params.data.analytics_info.ga4, label: 'GA4' }
          ];
          return createContainerWithSquares(squaresInfo)
        }
      },
      {
        field: 'ads_info',
        headerName: 'ADS Info',
        cellRenderer: (params) => {
          const squaresInfo = [
            { value: params.data.ads_info.ga, label: 'GA' },
            { value: params.data.ads_info.fa, label: 'FA' },
            { value: params.data.ads_info.ma, label: 'MA' }
          ]
          return createContainerWithSquares(squaresInfo)
        }
      },
      {
        field: 'lead_center_note',
        headerName: 'Note',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 900 * 60,
          rows: 10,
          cols: 50
        }
      }
    ]
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        MtApi.agGridLeadCenterOverview(params.request).then((response) => {
          this.$emitter.emit('status_bar_update', {
            count: response.count
          })
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
    this.cellValueChanged = (params) => {
      if (params.colDef.field === 'lead_center_note') {
        MtApi.updatePlatform(params.data.platform_id, {
          event: 'update_platform',
          field: {
            name: params.colDef.field,
            value: params.value
          }
        })
      }
    }
  }
}
</script>
